var firebaseConfig = {
  apiKey: "AIzaSyASkJSdo5pzGGeCRzdrt1uh-f45SQcImp8",
  authDomain: "neo-stack.firebaseapp.com",
  projectId: "neo-stack",
  databaseURL: "https://neo-stack.firebaseio.com",
  storageBucket: "neo-stack.appspot.com",
  messagingSenderId: "377029539681",
  appId: "1:377029539681:web:ffeb2a08593b1d56577720",
  measurementId: "G-W9DHJKMQKX"
};

export default firebaseConfig;




